import React from 'react'

interface TokenResponse {
  id_token: string
  access_token: string
  refresh_token: string
  expires_in: number
  token_type: 'Bearer'
}
export interface UserInfo {
  sub: string
  'custom:clientId': string
  email: string
}

export interface AuthState {
  isLoggedIn: boolean
  loading: boolean
  session?: TokenResponse
  userInfo?: UserInfo
  error?: boolean
  loginUrl: string
  signOut: () => void
}

const signIn = async (code: string): Promise<TokenResponse> => {
  const payload: Record<string, string> = {
    grant_type: 'authorization_code',
    code,
    client_id: process.env.REACT_APP_COGNITO_CLIENT_ID!,
    redirect_uri: process.env.REACT_APP_COGNITO_REDIRECT_URI!,
  }

  const formBody = Object.keys(payload)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`)
    .join('&')

  try {
    const response = await fetch(`${process.env.REACT_APP_COGNITO_CLIENT_DOMAIN}/oauth2/token`, {
      method: 'post',
      body: formBody,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    if (!response.ok) {
      throw new Error(await response.text())
    }

    return await response.json()
  } catch (error) {
    console.error('Error signing in: ', error)
    throw error
  }
}

const AuthContext = React.createContext<AuthState>({
  isLoggedIn: false,
  loading: false,
  error: false,
  loginUrl: '',
  signOut: () => {},
})

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [session, setSession] = React.useState<TokenResponse>()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [userInfo, setUserInfo] = React.useState<UserInfo>()

  const signOut = () => {
    setSession(undefined)
    setUserInfo(undefined)
    window.location.href = '/'
  }

  React.useEffect(() => {
    if (session?.id_token) {
      const parts = session.id_token.split('.')
      setUserInfo(JSON.parse(atob(parts[1])))
    }
  }, [session])

  React.useEffect(() => {
    if (session || loading || error) {
      return
    }
    setLoading(true)
    const params = new URLSearchParams(window.location.search)
    const code = params.get('code')

    if (code) {
      signIn(code)
        .then(tokens => {
          setSession(tokens)
        })
        .catch(e => {
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [loading, session, error])

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!session,
        session,
        userInfo,
        loading,
        error,
        loginUrl: `${process.env.REACT_APP_COGNITO_CLIENT_DOMAIN}/login?response_type=code&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI}`,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => React.useContext(AuthContext)
