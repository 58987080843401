import React from 'react'
import { useAuthContext } from './auth'
import { BaseServiceApi } from '../../../types/platform-api'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export interface APIState {
  callEndpoint: <A extends BaseServiceApi, K extends keyof A['endpoints']>(
    service: A['path'],
    endpoint: string,
    method: A['endpoints'][K]['method'],
    payload?: A['endpoints'][K]['input'],
  ) => Promise<A['endpoints'][K]['output']>
}

const APIContext = React.createContext<APIState>({
  callEndpoint: async () => undefined,
})

export const APIProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { session } = useAuthContext()

  const callEndpoint = async <A extends BaseServiceApi, K extends keyof A['endpoints']>(
    service: A['path'],
    endpoint: string,
    method: A['endpoints'][K]['method'],
    payload?: A['endpoints'][K]['input'],
  ): Promise<A['endpoints'][K]['output']> => {
    if (!session) {
      throw new Error('unauthenticated')
    }

    try {
      const response = await fetch(`${BASE_URL}/${service}${endpoint}`, {
        method,
        body: payload ? JSON.stringify(payload) : undefined,
        headers: {
          Accept: 'application/json',
          Authorization: `${session.id_token}`,
        },
      })

      if (!response.ok) {
        const errorText = await response.text()
        throw new Error(`Error ${response.status}: ${errorText}`)
      }

      return response.json()
    } catch (error) {
      console.error('API call error:', error)
      throw error
    }
  }

  return (
    <APIContext.Provider
      value={{
        callEndpoint,
      }}
    >
      {children}
    </APIContext.Provider>
  )
}

export const useAPIContext = () => React.useContext(APIContext)
