import React from 'react'
import { useAPIContext } from '../context/api'
import { InstagramAPI } from '../../../constructs/api/instagram/interface'

import { Card, Form, Input, Typography, Image, Skeleton } from 'antd'
import { InputState } from '../../types/form'
import { LinkOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

const { Text } = Typography

const PREVIEW_WIDTH = 72
const PREVIEW_HEIGHT = 128
const VALID_IG_LINK = /https:\/\/www\.instagram.com\/reel\/\S{9,}\/?/

const cardStyles = {
  body: { padding: 10 },
  container: { marginBottom: '10px', padding: '0px 8px', height: 'auto' },
  previewContainer: { width: PREVIEW_WIDTH, height: PREVIEW_HEIGHT },
}

interface CardComponentProps {
  input: InputState
  required: boolean
  handleInputChange: (id: number, field: 'url' | 'cta' | 'title', value: string) => void
}

interface InstagramPreviewProps {
  reelUrl: string
  previewUrl: string | undefined
  loading: boolean
}

const InstagramPreview: React.FC<InstagramPreviewProps> = React.memo(
  ({ reelUrl, previewUrl, loading }) => {
    if (!VALID_IG_LINK.test(reelUrl)) return null

    return (
      <div style={cardStyles.previewContainer}>
        {loading ? (
          <Skeleton.Image active style={cardStyles.previewContainer} />
        ) : (
          <Image
            src={previewUrl}
            width={PREVIEW_WIDTH}
            height={PREVIEW_HEIGHT}
            style={{ objectFit: 'cover', borderRadius: 3 }}
            preview={true}
          />
        )}
      </div>
    )
  },
)

function cleanReelUrl(url: string): string {
  const urlObj = new URL(url);
  return `${urlObj.origin}${urlObj.pathname}`;
}

export const CardContent: React.FC<CardComponentProps> = ({
  input,
  required,
  handleInputChange,
}) => {
  const { callEndpoint } = useAPIContext()
  const [reelState, setReelState] = React.useState({
    title: '',
    previewUrl: '',
    loading: false
  })
  const isLoaded = !!input.url && reelState.previewUrl

  const fetchReelData = React.useCallback(
    async (reelUrl: string) => {
      if (!VALID_IG_LINK.test(reelUrl)) {
        setReelState({ title: '', previewUrl: '', loading: false })
        return
      }
      setReelState(prev => ({ ...prev, loading: true }))
      try {
        const cleanUrl = cleanReelUrl(reelUrl); 
        const response = await callEndpoint<InstagramAPI, 'get-preview'>('instagram', '', 'POST', {
          reelUrl: cleanUrl,
        })

        if(response) {
          setReelState({
            title: response.title || '',
            previewUrl: response.previewUrl,
            loading: false
          })
          if (!input.title) {
            handleInputChange(input.id, 'title', response.title)
          }
        }
      } catch (err) {
        console.error('Error fetching reel data:', err)
      } finally {
        setReelState(prev => ({ ...prev, loading: false }))
      }
    },
    [callEndpoint],
  )

  const handleFocusCTA = () => {
    if (input.cta === input.url) {
      handleInputChange(input.id, 'cta', '')
    }
  }

  const handleBlurCTA = () => {
    if (input.cta.trim() === '') {
      handleInputChange(input.id, 'cta', input.url)
    }
  }

  const handleBlurTitle = () => {
    if (input.title.trim() === '') {
      handleInputChange(input.id, 'title', reelState.title)
    }
  }

  React.useEffect(() => {
    if (input.url && input.isValid) fetchReelData(input.url)
  }, [input.url, input.isValid, fetchReelData])

  return (
    <Card key={input.id} style={cardStyles.container}>
      <Text
        style={{
          display: 'block',
          fontWeight: 'bold',
          fontSize: '13px',
          color: '#3E5569',
        }}
      >
        {`Contenido ${input.id + 1} ${required ? '*' : '(opcional)'}`}
      </Text>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Form.Item
            name={`link${input.id}`}
            rules={[
              {
                required: required,
                message: 'Ingresar una URL válida',
              },
            ]}
            style={{ margin: 0 }}
          >
            <Input
              value={input.url}
              onChange={e => handleInputChange(input.id, 'url', e.target.value)}
              prefix={<LinkOutlined />}
              suffix={
                input.isValid && isLoaded && input.url !== '' ? (
                  <CheckCircleOutlined style={{ color: '#119400' }} />
                ) : !input.isValid && isLoaded ? (
                  <ExclamationCircleOutlined style={{ color: '#FF4D4F' }} />
                ) : null
              }
              placeholder='Pega tu URL aquí'
              style={{ color: isLoaded ? '#A7A7A7' : 'inherit' }}
            />
          </Form.Item>

          <Form.Item
            name={`cta${input.id}`}
            rules={[
              {
                required: required,
                message: 'Ingresar una URL válida',
              },
            ]}
            style={{ margin: 0 }}
          >
            <Input
              value={input.cta}
              onChange={e => handleInputChange(input.id, 'cta', e.target.value)}
              onFocus={handleFocusCTA}
              onBlur={handleBlurCTA}
              placeholder='Ingresa el enlace del CTA'
              style={{ color: isLoaded ? '#A7A7A7' : 'inherit' }}
            />
          </Form.Item>

          <Form.Item
            name={`title${input.id}`}
            rules={[
              {
                required: required,
                message: 'Ingresar un título válido',
              },
            ]}
            style={{ margin: 0 }}
          >
            <Input
              value={input.title || reelState.title}
              onChange={e => handleInputChange(input.id, 'title', e.target.value)}
              onBlur={handleBlurTitle}
              placeholder='Título del reel'
              style={{ color: isLoaded ? '#A7A7A7' : 'inherit' }}
            />
          </Form.Item>
        </div>

        {input.showPlaceholder ? (
          <div
            style={{
              width: PREVIEW_WIDTH,
              height: PREVIEW_HEIGHT,
              backgroundColor: '#F8F7F7',
              borderRadius: 3,
            }}
          ></div>
        ) : (
          <InstagramPreview
            reelUrl={input.url}
            previewUrl={reelState.previewUrl}
            loading={reelState.loading}
          />
        )}
      </div>
    </Card>
  )
}
