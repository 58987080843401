import React from 'react'
import './App.css'
import { useAuthContext } from './context/auth'
import { Layout, Button } from 'antd'
import { EditFormPage } from './pages/edit-form'
import Logo from './components/logo'

const App: React.FC = () => {
  const { isLoggedIn, loginUrl, signOut } = useAuthContext()

  return (
    <div className='App'>
      {isLoggedIn ? (
        <Layout style={{ display: 'flex', flexDirection: 'column' }}>
          <Layout.Header
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Logo />
            <Button type='primary' onClick={signOut}>
              Salir
            </Button>
          </Layout.Header>
          <Layout.Content
            style={{
              backgroundColor: '#F9FAFF',
              flex: 1,
              overflowY: 'auto',
            }}
          >
            <EditFormPage />
          </Layout.Content>
          <Layout.Footer style={{ backgroundColor: '#F9FAFF' }}>Drupi © 2024</Layout.Footer>
        </Layout>
      ) : (
        <div className='Button-container'>
          <Button size='large' type='primary' href={loginUrl} style={{ textDecoration: 'none' }}>
            Ingresar
          </Button>
        </div>
      )}
    </div>
  )
}

export default App
