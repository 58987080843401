import React from 'react'

const Logo = () => {
  return (
    <div style={{ marginTop: '2em' }}>
      <a href='https://www.drupi.io/' target='_blank' rel='noopener noreferrer'>
        <svg
          width='71'
          height='30'
          viewBox='0 0 71 30'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M8.58414 23H0.694141V1.07H8.37414C14.8541 1.07 19.3241 5.54 19.3241 12.08C19.3241 18.53 14.9441 23 8.58414 23ZM8.01414 4.61H4.53414V19.46H8.22414C12.6041 19.46 15.2741 16.64 15.2741 12.08C15.2741 7.43 12.5441 4.61 8.01414 4.61ZM30.6565 8.12V11.51H29.3065C26.6665 11.51 25.0165 12.92 25.0165 15.8V23H21.3565V8.21H24.8065L25.0165 10.37C25.6465 8.9 27.0565 7.91 29.0365 7.91C29.5465 7.91 30.0565 7.97 30.6565 8.12ZM42.1793 8.18H45.8393V23H42.4493L42.1793 21.02C41.2793 22.43 39.3593 23.39 37.3793 23.39C33.9593 23.39 31.9493 21.08 31.9493 17.45V8.18H35.6093V16.16C35.6093 18.98 36.7193 20.12 38.7593 20.12C41.0693 20.12 42.1793 18.77 42.1793 15.95V8.18ZM48.6149 29.87V8.18H52.0049L52.2449 10.43C53.1449 8.72 55.0949 7.73 57.3449 7.73C61.5149 7.73 64.2749 10.76 64.2749 15.41C64.2749 20.03 61.7549 23.39 57.3449 23.39C55.1249 23.39 53.2049 22.52 52.2749 21.02V29.87H48.6149ZM52.3049 15.59C52.3049 18.26 53.9549 20.09 56.4749 20.09C59.0549 20.09 60.5849 18.23 60.5849 15.59C60.5849 12.95 59.0549 11.06 56.4749 11.06C53.9549 11.06 52.3049 12.92 52.3049 15.59ZM68.0144 5.21C66.7544 5.21 65.7644 4.22 65.7644 2.99C65.7644 1.76 66.7544 0.8 68.0144 0.8C69.2144 0.8 70.2044 1.76 70.2044 2.99C70.2044 4.22 69.2144 5.21 68.0144 5.21ZM66.1844 23V8.18H69.8444V23H66.1844Z'
            fill='white'
          />
        </svg>
      </a>
    </div>
  )
}

export default Logo
