import React from 'react'
import dayjs from 'dayjs'
import {
  Button,
  Flex,
  Form,
  DatePicker,
  Skeleton,
  Row,
  Col,
  Card,
  Typography,
  Divider,
  message,
} from 'antd'
import { CalendarOutlined } from '@ant-design/icons'

import { InputState } from '../../types/form'
import { useAPIContext } from '../context/api'
import { CarouselAPI } from '../../../constructs/api/carousel/interface'
import { CalendarAPI } from '../../../constructs/api/calendar/interface'
import { CardContent } from '../components/card-content'
import { CalendarContent } from '../../../types/calendar'

const { Paragraph, Link, Text } = Typography
const today = dayjs()

const MAX_INPUTS = 5
const REQUIRED_INPUTS = 4
const VALID_URL = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/

const initialInputState: InputState = {
  id: 0,
  url: '',
  cta: '',
  title: '',
  showPlaceholder: true,
  isValid: false,
  order: 0,
  ctaUpdated: false,
  titleUpdated: false,
}

const generateInitialInputs = (length: number): InputState[] =>
  Array.from({ length }, (_, index) => ({
    ...initialInputState,
    id: index,
    order: index + 1,
  }))

export const EditFormPage: React.FC = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const [isFormComplete, setIsFormComplete] = React.useState(false)
  const [showReminder, setShowReminder] = React.useState(true)
  const [inputs, setInputs] = React.useState<InputState[]>(() => generateInitialInputs(MAX_INPUTS))
  const [fechasConContenido, setFechasConContenido] = React.useState<string[]>([])

  const inputsToShow = inputs.slice(0, MAX_INPUTS)
  const date = Form.useWatch('date', form)

  const { callEndpoint } = useAPIContext()

  const fetchCarouselData = React.useCallback(
    (selectedDate: dayjs.Dayjs) => {
      setLoading(true)
      callEndpoint<CarouselAPI, 'get'>('carousel', `/${selectedDate.format('YYYY-MM-DD')}`, 'GET')
        .then(carousel => {
          const content = carousel.content || []
          const formValues: Record<string, string> = {}
          const updatedInputs = Array.from({ length: MAX_INPUTS }, (_, index) => {
            const carouselItem = content[index] || {}
            formValues[`link${index}`] = carouselItem.link || ''
            formValues[`cta${index}`] = carouselItem.cta || ''
            formValues[`title${index}`] = carouselItem.title || ''
            return {
              ...initialInputState,
              id: index,
              order: index,
              url: carouselItem.link || '',
              cta: carouselItem.cta || '',
              title: carouselItem.title || '',
              showPlaceholder: !carouselItem.link,
              isValid: !!carouselItem.link,
              ctaUpdated: !!carouselItem.cta,
              titleUpdated: !!carouselItem.title,
            }
          })
          form.setFieldsValue(formValues)
          setInputs(updatedInputs)
          setShowReminder(content.every(item => !item.link))
        })
        .catch(err => {
          const errorMessage = err?.message || ''
          if (errorMessage.includes('404')) {
            const emptyInputs = generateInitialInputs(MAX_INPUTS)
            setInputs(emptyInputs)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [callEndpoint, form],
  )

  const fetchCarouselDates = React.useCallback(() => {
    setLoading(true);
    
    callEndpoint<CalendarAPI, 'get'>('calendar', '', 'GET')
      .then(calendar => {
        const content = calendar || [];
        const calendarDates: string[] = content.map((calendarDateItem: CalendarContent) => {
          return calendarDateItem.date;
        });
        setFechasConContenido(calendarDates);
        
        return calendarDates;
      })
      .catch(err => {
        console.error('Error fetching calendar dates:', err);
        if (err?.message?.includes('404')) {
          return [];
        }
      })
      .finally(() => {
        setLoading(false);
      });
      return []
  }, [callEndpoint]);

  const handleForm = async (values: any) => {
    const date = values.date.format('YYYY-MM-DD')

    const carousel = {
      content: inputs
        .filter((_, index) => values[`link${index}`]?.trim().length)
        .map((input, index) => ({
          link: values[`link${index}`],
          cta: values[`cta${index}`],
          title: values[`title${index}`],
          order: input.order,
        })),
    }

    setSaving(true)

    try {
      const res = await callEndpoint<CarouselAPI, 'save'>('carousel', `/${date}`, 'POST', carousel)

      if (!res) throw new Error('Respuesta del servidor inesperada')

      message.success('El contenido se ha enviado exitosamente')
      setShowReminder(false)
    } catch (error: any) {
      message.error('Hubo un error al enviar el contenido. Intenta nuevamente')
    } finally {
      setSaving(false)
    }
  }

  const handleInputChange = (id: number, field: 'url' | 'cta' | 'title', value: string) => {
    setInputs(prevInputs => {
      return prevInputs.map(input => {
        if (input.id === id) {
          const updates: Partial<InputState> = { [field]: value }
          if (field === 'url') {
            updates.showPlaceholder = !value
            updates.isValid = VALID_URL.test(value)
            if(!value) {
              updates.title = ''
            }
            updates.cta = value
            updates.title = ''           
          } else if (field === 'cta') {
            updates.ctaUpdated = true
          } else if (field === 'title') {
            updates.title = value.replace(/\n+/g, ' ')
            updates.titleUpdated = true
          }

          return { ...input, ...updates }
        }
        return input
      })
    })
  }

  React.useEffect(() => {
    const formValues: Record<string, string> = {}
    inputs.forEach((input, index) => {
      formValues[`link${index}`] = input.url
      formValues[`cta${index}`] = input.cta
      formValues[`title${index}`] = input.title
    })
    form.setFieldsValue(formValues)
  }, [inputs, form])

  React.useEffect(() => {
    if (date) {
      fetchCarouselData(date)
    }
  }, [date, fetchCarouselData])

  React.useEffect(() => {
    const allUrlsFilled = inputs
      .slice(0, REQUIRED_INPUTS)
      .every(
        input => input.url.trim() !== '' && input.cta.trim() !== '' && input.title.trim() !== '',
      )
    setIsFormComplete(allUrlsFilled && !!date)
  }, [inputs, date])

  React.useEffect(() => {
      fetchCarouselDates()
  }, [fetchCarouselDates])

  return (
    <Flex style={{ height: '100%', overflowX: 'hidden' }}>
      <Row gutter={[48, 24]} style={{ width: '100%', paddingTop: '2em' }}>
        <Col xs={{ span: 24, offset: 1 }} lg={{ span: 10, offset: 1 }}>
          <Card style={{ textAlign: 'left', width: '100%' }}>
            <Typography.Title level={3} style={{ marginTop: 0, marginBottom: 0 }}>
              👋 Bienvenido al content management
            </Typography.Title>
            <Paragraph>Cargá los contenidos de tus publicaciones siguiendo estos pasos:</Paragraph>
            <Card
              style={{
                border: 'none',
                backgroundColor: '#F9FAFF',
                borderRadius: '8px',
              }}
            >
              <Paragraph>
                1. Selecciona la fecha en la que quieras cargar el contenido del widget
              </Paragraph>
              <Paragraph>2. Copia y pega el link del reel</Paragraph>
              <Paragraph>3. Agrega un título</Paragraph>
              <Paragraph>
                4. Hace click en <strong>enviar</strong> y listo! tus contenidos quedan programados.
              </Paragraph>
            </Card>
            <Divider />
            <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
              Si tenés dudas o algún problema, envianos un email a:
              <Paragraph style={{ marginBottom: 0 }}>
                <Link style={{ fontSize: '18px' }}>soporte@drupi.io</Link>
              </Paragraph>
            </Typography.Title>
          </Card>
        </Col>

        <Col xs={{ span: 24, offset: 1 }} lg={11}>
          <Form layout='vertical' form={form} onFinish={handleForm} requiredMark={false}>
            <Flex
              justify='space-between'
              style={{ marginBottom: '1em', display: 'flex', alignItems: 'center' }}
            >
              <div>
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Carga tus contenidos
                </Typography.Title>
              </div>

              <div>
                <Form.Item
                  name='date'
                  rules={[{ type: 'object', required: true, message: 'Seleccionar una fecha' }]}
                  style={{ marginBottom: 0, flexGrow: 1, width: '100%' }}
                  initialValue={today}
                >
                  <DatePicker
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      padding: '8px 16px',
                    }}
                    className='custom-placeholder'
                    placeholder={'Seleccionar fecha'}
                    disabled={loading}
                    suffixIcon={<CalendarOutlined style={{ color: '#344054' }} />}
                    cellRender={(currentDate) => {
                      const isDayjs = currentDate && typeof currentDate === 'object' && 'format' in currentDate;
                      const isDateWithContent = isDayjs && fechasConContenido.includes(currentDate.format('YYYY-MM-DD'))
                      return (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: isDateWithContent ? '#FFFFFF' : undefined,
                            borderRadius: '50%',
                            width: '32px',
                            height: '32px',
                            backgroundColor: isDateWithContent ? '#8a8aff' : undefined,
                          }}
                        >
                          {isDayjs ? currentDate.date() : currentDate}
                        </div>
                      )
                    }}
                  />
                </Form.Item>
              </div>
            </Flex>

            {loading ? (
              <Skeleton />
            ) : (
              <>
                {inputsToShow.map(input => (
                  <CardContent
                    key={input.id}
                    input={input}
                    required={input.id < REQUIRED_INPUTS}
                    handleInputChange={handleInputChange}
                  />
                ))}

                <Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      {showReminder && isFormComplete && (
                        <Text
                          style={{
                            fontWeight: 'bold',
                            fontSize: '16px',
                            color: '#3E5569',
                          }}
                        >
                          Recorda enviar el contenido :)
                        </Text>
                      )}
                    </div>
                    <div>
                      <Button
                        type='primary'
                        style={{ width: '195px', height: '54px', border: 'none' }}
                        htmlType='submit'
                        loading={saving}
                        disabled={!isFormComplete}
                      >
                        Enviar
                      </Button>
                    </div>
                  </div>
                </Form.Item>
              </>
            )}
          </Form>
        </Col>
      </Row>
    </Flex>
  )
}
